import React from 'react';
import PropTypes from 'prop-types';
import Container from '../shared/Container/Container';
import SocialButton from '../SocialButton/SocialButton';
import {
  AuthProviders,
  AuthProviderItem,
  PageWrapper,
  PageContent,
  Title,
  Divider,
  AuthForm,
} from './styles';

const AuthPageView = ({
  children,
  title,
  helpText,
  loginWithGoogle,
  loginWithFb,
}) => (
  <PageWrapper>
    <Container>
      <PageContent>
        <Title>{title}</Title>
        <AuthProviders>
          <AuthProviderItem>
            <SocialButton title="Google" onClick={loginWithGoogle}>
              G+
            </SocialButton>
          </AuthProviderItem>
          <AuthProviderItem>
            <SocialButton title="Facebook" onClick={loginWithFb}>
              f
            </SocialButton>
          </AuthProviderItem>
        </AuthProviders>
        <Divider>or</Divider>
        <AuthForm>{children}</AuthForm>
        {helpText}
      </PageContent>
    </Container>
  </PageWrapper>
);

AuthPageView.propTypes = {
  title: PropTypes.string.isRequired,
  helpText: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  loginWithGoogle: PropTypes.func.isRequired,
  loginWithFb: PropTypes.func.isRequired,
};

export default AuthPageView;
