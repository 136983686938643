import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorTypes, loadingState } from 'helpers/constants';
import * as apiService from 'services/apiService';

const initialState = {
  data: {
    totalUniqProbes: 0,
    totalRepeatedProbes: 0,
    topReferralsAmount: 0,
    topOsList: [],
  },
  status: loadingState.IDLE,
  error: null,
};

export const fetchOverview = createAsyncThunk(
  'overview/fetch',
  async (params) => {
    const response = await apiService.getOverview(params);
    return response;
  }
);

const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOverview.pending]: (state) => {
      state.status = loadingState.LOADING;
      state.error = null;
    },
    [fetchOverview.fulfilled]: (state, { payload }) => {
      const data = { ...payload };
      if (!data.topOsList) {
        data.topOsList = [];
      }
      const topStations = data.topStations || [];
      data.topStations = topStations.map(({ ssid }) => ssid);
      state.data = data;
      state.status = loadingState.SUCCEEDED;
      state.error = null;
    },
    [fetchOverview.rejected]: (state, { error }) => {
      if (error.name !== errorTypes.ABORT_ERROR) {
        state.status = loadingState.FAILED;
        state.error = error;
      }
    },
  },
});

export default overviewSlice.reducer;

export const selectOverviewLoadingStatus = ({ overview }) => overview.status;
export const selectTotalProbes = ({ overview }) =>
  overview.data.totalUniqProbes;
export const selectTotalRepeatedProbes = ({ overview }) =>
  overview.data.totalRepeatedProbes;
export const selectTopReferralsAmount = ({ overview }) =>
  overview.data.topReferralsAmount;
export const selectOsList = ({ overview }) => overview.data.topOsList;
export const selectOverviewError = ({ overview }) => overview.error;
