import React from 'react';
import PropTypes from 'prop-types';
import VisuallyHidden from '../shared/VisuallyHidden/VisuallyHidden';
import { StyledSocialButton } from './styles';

const SocialButton = ({ children, title, ...rest }) => (
  <StyledSocialButton {...rest}>
    <VisuallyHidden>{title}</VisuallyHidden>
    {children}
  </StyledSocialButton>
);

SocialButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
};

SocialButton.defaultProps = {
  title: '',
};

export default SocialButton;
