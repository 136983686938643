import React from 'react';
import PropTypes from 'prop-types';
import AuthPageView from './AuthPageView';
import LoginForm from '../LoginForm/LoginForm';
import { HelpText, HelpTextLink } from './styles';

const BottomText = () => (
  <HelpText>
    {`New? `}
    <HelpTextLink
      to={({ state }) => {
        return { pathname: '/signup', state };
      }}
    >
      Create a free account
    </HelpTextLink>
  </HelpText>
);

const LoginView = ({
  onSubmit,
  loginWithGoogle,
  loginWithFb,
  resetPassword,
}) => (
  <AuthPageView
    title="Welcome back"
    helpText={<BottomText />}
    loginWithGoogle={loginWithGoogle}
    loginWithFb={loginWithFb}
  >
    <LoginForm onSubmit={onSubmit} resetPassword={resetPassword} />
  </AuthPageView>
);

LoginView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loginWithGoogle: PropTypes.func.isRequired,
  loginWithFb: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default LoginView;
