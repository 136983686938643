import { Table } from 'react-virtualized';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ReactVirtualized__Table__headerRow {
    display: flex;
    font-size: 12px;
    font-weight: 400;
  }

  .ReactVirtualized__Table__headerColumn {
    color: ${({ theme }) => theme.colors.green};
  }

  .ReactVirtualized__Table__row {
    display: flex;

    &.even {
      background-color: ${({ theme }) => theme.colors.grey};
    }
  }

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px;

    &:first-of-type {
      padding-left: 30px;
    }

    &:last-of-type {
      padding-right: 30px;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      &:first-of-type {
        padding-left: 50px;
      }

      &:last-of-type {
        padding-right: 50px;
      }
    }
  }

  .ReactVirtualized__Table__rowColumn {
    &:nth-of-type(2n) {
      font-weight: 700;
    }
  }
`;

export const RowPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
`;

export const NoRowRenderer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
