import React from 'react';
import PropTypes from 'prop-types';
import CrashScreen from 'components/CrashScreen/CrashScreen';
import { errorTypes } from 'helpers/constants';
import NotFound from 'scenes/NotFound/NotFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return error.message === errorTypes.CLIENT_ERROR ? (
        <NotFound />
      ) : (
        <CrashScreen>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error?.message}
            <br />
            {error.stack}
          </details>
        </CrashScreen>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorBoundary;
