import styled from 'styled-components';

export const StyledLabel = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const CheckMark = styled.span`
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 13px;
  background-color: ${({ theme }) => theme.colors.white};

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    display: none;
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const HiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 8px;
  width: 1px;

  &:checked ~ ${CheckMark} {
    &::before {
      display: block;
    }
  }
`;
