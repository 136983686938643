import React, { useEffect } from 'react';
import Overview from 'containers/Overview/Overview';
import Container from 'components/shared/Container/Container';
import TapBar from 'components/TapBar/TapBar';
import Stations from 'containers/Stations/Stations';
import { Route, Switch, useParams } from 'react-router-dom';
import Filters from 'containers/Filters/Filters';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOverview,
  selectOverviewLoadingStatus,
} from 'containers/Overview/overviewSlice';
import { loadingState, errorTypes } from 'helpers/constants';
import Header from 'components/Header/Header';
import Loader from 'components/shared/Loader/Loader';
import { selectFilters } from 'containers/Filters/filtersSlice';
import useAsyncError from 'hooks/useAsyncError';
import { unwrapResult } from '@reduxjs/toolkit';
import { DashboardPageWrapper, DashboardTableWrapper } from './styles';

const Dashboard = () => {
  const { uid } = useParams();
  const overviewStatus = useSelector(selectOverviewLoadingStatus);
  const dispatch = useDispatch();
  const { type, date, brandsOnly } = useSelector(selectFilters);
  const throwAsyncError = useAsyncError();

  useEffect(() => {
    const promise = dispatch(fetchOverview({ uid, type, date, brandsOnly }));
    promise.then(unwrapResult).catch((err) => {
      if (err.name === errorTypes.ABORT_ERROR) {
        return;
      }
      throwAsyncError(err);
    });
    return () => {
      promise.abort();
    };
  }, [uid, type, date, brandsOnly, dispatch, throwAsyncError]);

  const isOverviewLoading =
    overviewStatus === loadingState.IDLE ||
    overviewStatus === loadingState.LOADING;

  return (
    <Container>
      <DashboardPageWrapper>
        <Header />
        <TapBar />
        <Switch>
          <Route exact path={`/${uid}`}>
            <Filters uid={uid} />
            {isOverviewLoading ? (
              <Loader />
            ) : (
              <>
                <Overview />
                <DashboardTableWrapper>
                  <Stations uid={uid} />
                </DashboardTableWrapper>
              </>
            )}
          </Route>
          <Route path={`/${uid}/referrers`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <h1>Coming soon</h1>
            </div>
          </Route>
        </Switch>
      </DashboardPageWrapper>
    </Container>
  );
};

export default Dashboard;
