import Button from 'components/shared/Button/Button';
import styled from 'styled-components';

export const DateFiltersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
  padding: 0;
  list-style: none;
`;

export const DateFiltersItem = styled.li`
  padding: 0 6px;
`;

export const FiltersButton = styled(Button)`
  min-width: 85px;
  font-weight: 400;

  &:disabled {
    font-weight: 700;
  }
`;
