import styled from 'styled-components';

export const StyledInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 18px 40px;
  font: inherit;
  font-size: 15px;
  line-height: 18px;
  border: none;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};

  &::placeholder {
    font-weight: 300;
  }
`;
