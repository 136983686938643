import styled from 'styled-components';

export const TapBarList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const TapBarItem = styled.li`
  box-sizing: border-box;
  display: flex;
  width: calc(100% / 3);
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
`;
