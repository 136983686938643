import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledTabBarLink,
  TapBarLinkText,
  Count,
  UniqueVisitorsIcon,
  RepeatVisitorsIcon,
  ReferrersIcon,
} from './styles';

const TapBarLink = ({ children, count, type, ...rest }) => {
  const getIcon = (iconType) => {
    if (iconType === 'unique') return <UniqueVisitorsIcon />;
    if (iconType === 'repeat') return <RepeatVisitorsIcon />;
    if (iconType === 'referrers') return <ReferrersIcon />;
    return null;
  };

  if (count >= 1e3) {
    count = `${Math.trunc(count / 1e3)}K`;
  }

  return (
    <StyledTabBarLink {...rest}>
      {getIcon(type)}
      <TapBarLinkText>
        <Count>{count}</Count>
        {children}
      </TapBarLinkText>
    </StyledTabBarLink>
  );
};

TapBarLink.propTypes = {
  count: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.string,
};

TapBarLink.defaultProps = {
  count: 0,
  type: '',
};

export default TapBarLink;
