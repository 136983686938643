import React from 'react';
import PropTypes from 'prop-types';

import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  InfiniteLoader,
} from 'react-virtualized';
import Loader from 'components/shared/Loader/Loader';
import { RowPlaceholder, StyledTable, NoRowRenderer } from './styles';

const Table = ({
  columns,
  items,
  hasNextPage,
  loadMore,
  isNextPageLoading,
}) => {
  const rowCount = hasNextPage ? items.length + 1 : items.length;
  const isRowLoaded = ({ index }) => !hasNextPage || index < items.length;
  const loadMoreRows = isNextPageLoading ? () => {} : loadMore;

  const rowRenderer = ({ index, style, ...rest }) => {
    if (!isRowLoaded({ index })) {
      return (
        <RowPlaceholder key={index * Math.random()} style={style}>
          <Loader size="small" />
        </RowPlaceholder>
      );
    }

    return defaultTableRowRenderer({ index, style, ...rest });
  };

  const noRowsRenderer = () => (
    <NoRowRenderer>
      <div style={{ fontSize: '15px' }}>¯\_(ツ)_/¯</div>
      No data is available
    </NoRowRenderer>
  );

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      threshold={10}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <StyledTable
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              headerHeight={36}
              width={width}
              height={height}
              rowCount={rowCount}
              rowHeight={36}
              rowGetter={({ index }) => items[index] || {}}
              rowRenderer={rowRenderer}
              noRowsRenderer={noRowsRenderer}
              rowClassName={({ index }) => (index % 2 === 0 ? 'even' : '')}
            >
              {columns.map(({ label, dataKey, size }) => (
                <Column
                  key={dataKey}
                  label={label}
                  dataKey={dataKey}
                  width={size * width}
                />
              ))}
            </StyledTable>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      dataKey: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  hasNextPage: PropTypes.bool,
  loadMore: PropTypes.func,
  isNextPageLoading: PropTypes.bool,
};

Table.defaultProps = {
  items: [],
  hasNextPage: false,
  loadMore: () => {},
  isNextPageLoading: false,
};

export default Table;
