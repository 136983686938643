import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { HiddenInput, CheckMark, StyledLabel } from './styles';

const Checkbox = forwardRef(({ children, ...rest }, ref) => (
  <StyledLabel>
    <HiddenInput {...rest} ref={ref} type="checkbox" />
    <CheckMark />
    {children}
  </StyledLabel>
));

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default Checkbox;
