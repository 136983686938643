import React from 'react';
import TapBarLink from 'components/TapBarLink/TapBarLink';
import { useDispatch, useSelector } from 'react-redux';
import { selectTypeFilter, setType } from 'containers/Filters/filtersSlice';
import { probTypes } from 'helpers/constants';
import {
  selectTopReferralsAmount,
  selectTotalProbes,
  selectTotalRepeatedProbes,
} from 'containers/Overview/overviewSlice';
import { removeStations } from 'containers/Stations/stationsSlice';
import { useParams } from 'react-router-dom';
import { TapBarItem, TapBarList } from './styles';

const TapBar = () => {
  const totalUniqProbes = useSelector(selectTotalProbes);
  const totalRepeatedProbes = useSelector(selectTotalRepeatedProbes);
  const topReferralsAmount = useSelector(selectTopReferralsAmount);
  const dispatch = useDispatch();

  const { uid } = useParams();

  const type = useSelector(selectTypeFilter);

  const handleClick = (value) => {
    if (type !== value) {
      dispatch(setType(value));
      dispatch(removeStations());
    }
  };

  return (
    <div>
      <TapBarList>
        <TapBarItem>
          <TapBarLink
            count={totalUniqProbes}
            exact
            onClick={() => handleClick(probTypes.UNIQUE)}
            to={`/${uid}`}
            type="unique"
            isActive={(match) => match && type === probTypes.UNIQUE}
          >
            Unique visitors
          </TapBarLink>
        </TapBarItem>
        <TapBarItem>
          <TapBarLink
            count={totalRepeatedProbes}
            exact
            to={`/${uid}`}
            type="repeat"
            isActive={(match) => match && type === probTypes.REPEATED}
            onClick={() => handleClick(probTypes.REPEATED)}
          >
            Repeat visitors
          </TapBarLink>
        </TapBarItem>
        <TapBarItem>
          <TapBarLink
            count={topReferralsAmount}
            exact
            to={`/${uid}/referrers`}
            type="referrers"
          >
            Popular referrers
          </TapBarLink>
        </TapBarItem>
      </TapBarList>
    </div>
  );
};

export default TapBar;
