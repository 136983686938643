import styled from 'styled-components';

export const DashboardPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const DashboardTableWrapper = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  min-height: 320px;
  margin: 0 -30px;
  padding-top: 28px;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 -50px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin: 0 0;
  }
`;
