import React from 'react';
import PropTypes from 'prop-types';
import BrandsFilter from 'components/BrandsFilter/BrandsFilter';
import DateFilters from 'components/DateFilters/DateFilters';
import RefreshButton from 'containers/RefreshButton/RefreshButton';
import { StyledFilters } from './styles';

const Filters = ({ uid }) => (
  <StyledFilters>
    <DateFilters />
    <BrandsFilter />
    <RefreshButton uid={uid} />
  </StyledFilters>
);

Filters.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default Filters;
