import React from 'react';
import PropTypes from 'prop-types';
import theme from 'styles/theme';
import { StyledLoader } from './styles';

const Loader = ({ size, color }) => <StyledLoader size={size} color={color} />;

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: 'medium',
  color: theme.colors.green,
};

export default Loader;
