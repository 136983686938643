import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as UniqueVisitorsLogo } from 'assets/img/unique-visitors.svg';
import { ReactComponent as RepeatVisitorsLogo } from 'assets/img/repeat-visitors.svg';
import { ReactComponent as ReferrersLogo } from 'assets/img/referrers.svg';

export const StyledTabBarLink = styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-grow: 1;
  padding: 0 0 15px;
  font: inherit;
  font-size: 12px;
  text-decoration: none;
  text-align: center;
  color: currentColor;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -50%;
    display: block;
    width: 100%;
    height: 2px;
  }

  &.active {
    &::after {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const TapBarLinkText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Count = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: ${({ theme }) => theme.colors.green};
`;

export const UniqueVisitorsIcon = styled(UniqueVisitorsLogo)`
  flex-shrink: 0;
  align-self: flex-end;
  transform: translateY(-3px);
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const RepeatVisitorsIcon = styled(RepeatVisitorsLogo)`
  flex-shrink: 0;
  align-self: flex-end;
  transform: translateY(-3px);
  width: 23px;
  height: 17px;
  margin-right: 5px;
`;

export const ReferrersIcon = styled(ReferrersLogo)`
  flex-shrink: 0;
  align-self: flex-end;
  transform: translateY(-3px);
  width: 18px;
  height: 23px;
  margin-right: 5px;
`;
