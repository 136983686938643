import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledPageWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px 0 40px;
`;

export const StyledLink = styled(Link)`
  color: currentColor;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
`;

export const StyledButton = styled.button`
  box-sizing: border-box;
  padding: 0;
  font: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
