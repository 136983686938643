import Toggle from 'components/shared/Toggle/Toggle';
import {
  selectBrandsOnly,
  setBrandsOnly,
} from 'containers/Filters/filtersSlice';
import { removeStations } from 'containers/Stations/stationsSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const BrandsFilter = () => {
  const checked = useSelector(selectBrandsOnly);
  const dispatch = useDispatch();

  const onChange = (e) => {
    const value = !e.target.checked;
    dispatch(removeStations());
    dispatch(setBrandsOnly(value));
  };
  return (
    <Toggle checked={checked} onChange={onChange}>
      Brands
    </Toggle>
  );
};

export default BrandsFilter;
