import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/Button/Button';
import Input from '../shared/Input/Input';
import { FormField, HelpText, HelpTextButton, StyledLoginForm } from './styles';

const LoginForm = ({ onSubmit, resetPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const emailRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ email, password });
  };

  const handleResetPassword = () => {
    const emailInput = emailRef.current;
    const isEmailValid = emailInput?.checkValidity();
    const value = emailInput?.value;
    if (isEmailValid) {
      resetPassword(value);
    } else {
      emailInput.reportValidity();
    }
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <FormField>
        <Input
          ref={emailRef}
          name="email"
          placeholder="Email"
          required
          autoComplete="email"
          type="email"
          title="Invalid email format"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormField>
      <FormField>
        <Input
          name="password"
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormField>
      <HelpText>
        {`Lost password? `}
        <HelpTextButton type="button" onClick={handleResetPassword}>
          Retrieve new
        </HelpTextButton>
      </HelpText>
      <Button color="gold" size="large" type="submit">
        Log in
      </Button>
    </StyledLoginForm>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

export default LoginForm;
