import React from 'react';
import catchErrorDecorator from 'helpers/catchErrorDecorator';
import useAuth from 'hooks/useAuth';
import { useFirebase } from 'react-redux-firebase';
import SignupView from '../../components/AuthPageView/SignupView';

const Signup = () => {
  useAuth();

  const firebase = useFirebase();
  const loginWithProvider = catchErrorDecorator(firebase.login.bind(firebase));

  const loginWithGoogle = () =>
    loginWithProvider({ provider: 'google', type: 'popup' });

  const loginWithFb = () =>
    loginWithProvider({ provider: 'facebook', type: 'popup' });

  const createNewUser = catchErrorDecorator(firebase.createUser.bind(firebase));
  const createUser = ({ email, password }) =>
    createNewUser({ email, password });

  return (
    <SignupView
      onSubmit={createUser}
      loginWithGoogle={loginWithGoogle}
      loginWithFb={loginWithFb}
    />
  );
};

export default Signup;
