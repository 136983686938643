import axios from 'axios';
import { errorTypes } from './constants';
// import { getToken } from 'services/firebase';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export default async function callWebApi(config) {
  const { headers } = config;
  // const token = await getToken();
  const requestConfig = {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    withCredentials: false,
  };
  // if (token) {
  //   requestConfig.headers.Authorization = `Bearer ${token}`;
  // }

  return axios(requestConfig).catch((error) => {
    if (error.response) {
      const { status } = error.response;
      if (status >= 400 && status < 500) {
        throw new Error(errorTypes.CLIENT_ERROR);
      }

      throw error;
    }
    throw error;
  });
}
