import { timePeriods } from './constants';

const formatDate = (date, period) => {
  if (period === timePeriods.TODAY) {
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  return date.toLocaleString('en-US', { weekday: 'long' });
};

export default formatDate;
