import styled from 'styled-components';

export const StyledOverview = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.section`
  flex-grow: 1;
  max-width: 50%;
  margin-right: 20px;

  &:last-child {
    flex-grow: 0;
    margin-right: 0;
  }
`;

export const SectionTitle = styled.h3`
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  font-size: 14px;
`;
