import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import toPercents from 'helpers/toPercents';
import { selectStationById } from 'containers/Stations/stationsSlice';
import {
  Percentage,
  StyledReferrersItem,
  Referrer,
  StyledArrow,
  ArrowWrapper,
  Text,
} from './styles';

const getColor = (i) => {
  if (i < 2) return 'gold';
  if (i < 4) return 'green';
  return 'blue';
};

const ReferrerItem = ({ id, i, totalProbes }) => {
  const referrer = useSelector((state) => selectStationById(state, id));
  if (!referrer) {
    return null;
  }
  const { ssid, stationProbes } = referrer;
  const percentage = `${toPercents(stationProbes, totalProbes) || 1}%`;

  return (
    <StyledReferrersItem key={ssid} color={getColor(i)}>
      <Referrer>
        <Text>{ssid}</Text>
        <ArrowWrapper>
          <StyledArrow />
        </ArrowWrapper>
      </Referrer>
      <Percentage>
        <Text>{percentage}</Text>
      </Percentage>
    </StyledReferrersItem>
  );
};

ReferrerItem.propTypes = {
  id: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  totalProbes: PropTypes.number.isRequired,
};

export default ReferrerItem;
