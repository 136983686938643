import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PageWrapper = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};
`;

export const PageContent = styled.div`
  box-sizing: border-box;
  max-width: 500px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const Title = styled.h1`
  margin: 0;
  margin-bottom: 63px;
  text-align: center;
  font-size: 35px;
  line-height: 1.17em;
`;

export const AuthProviders = styled.ul`
  box-sizing: border-box;
  margin: 0 -24px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const AuthProviderItem = styled.li`
  padding: 0 24px;
`;

export const AuthForm = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0 29px;
  font-size: 15px;

  &::before,
  &::after {
    content: '';
    width: 20px;
    flex-grow: 1;
    height: 1px;
    background-color: currentColor;
  }

  &::before {
    margin-right: 22px;
  }

  &::after {
    margin-left: 22px;
  }
`;

export const HelpText = styled.p`
  padding: 0;
  margin: 0;
  padding-top: 44px;
  font-size: 15px;
  line-height: 1.15;
`;

export const HelpTextLink = styled(Link)`
  font: inherit;
  color: currentColor;
  text-decoration: none;
  font-weight: 700;
`;
