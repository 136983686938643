import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from '../shared/Button/Button';
import Input from '../shared/Input/Input';
import { FormField, HelpText, HelpTextLink, StyledLoginForm } from './styles';
import Checkbox from '../shared/Checkbox/Checkbox';

const SignupForm = forwardRef(({ onSubmit }, ref) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [consent, setConsent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ email, password });
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit}>
      <FormField>
        <Input
          name="email"
          placeholder="Email"
          required
          autoComplete="email"
          type="email"
          title="Invalid email format"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormField>
      <FormField>
        <Input
          name="password"
          type="password"
          placeholder="Password"
          required
          autoComplete="new-password"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormField>
      <HelpText>
        <Checkbox
          value={consent}
          ref={ref}
          onChange={() => setConsent(!consent)}
          name="consent"
          required
        >
          {'I agree to the\u00a0'}
          <HelpTextLink to="/terms">Terms of Use</HelpTextLink>
        </Checkbox>
      </HelpText>
      <Button color="red" size="large" type="submit">
        Sign up
      </Button>
    </StyledLoginForm>
  );
});

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
