import React from 'react';
import PropTypes from 'prop-types';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectFilters } from 'containers/Filters/filtersSlice';
import { errorTypes } from 'helpers/constants';
import useAsyncError from 'hooks/useAsyncError';
import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from './styles';
import refreshData from './refreshData';

const RefreshButton = ({ uid }) => {
  const { type, date, brandsOnly } = useSelector(selectFilters);

  const dispatch = useDispatch();
  const throwAsyncError = useAsyncError();

  const onClick = (e) => {
    e.preventDefault();
    const promise = dispatch(
      refreshData({
        uid,
        filter: { type, date, brandsOnly },
      })
    );
    promise.then(unwrapResult).catch((err) => {
      if (err.name === errorTypes.ABORT_ERROR) {
        return;
      }
      throwAsyncError(err);
    });
    return () => {
      if (promise) promise.abort();
    };
  };

  return <StyledButton onClick={onClick}>Refresh</StyledButton>;
};

RefreshButton.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default RefreshButton;
