import { createSlice } from '@reduxjs/toolkit';
import { probTypes, timePeriods } from 'helpers/constants';

const initialState = {
  type: probTypes.UNIQUE,
  date: timePeriods.TODAY,
  brandsOnly: true,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setType(state, { payload }) {
      state.type = payload;
    },
    setDate(state, { payload }) {
      state.date = payload;
    },
    setBrandsOnly(state, { payload }) {
      state.brandsOnly = payload;
    },
  },
});

export const { setType, setDate, setBrandsOnly } = filtersSlice.actions;

export default filtersSlice.reducer;

export const selectFilters = ({ filters }) => filters;
export const selectTypeFilter = ({ filters }) => filters.type;
export const selectDateFilter = ({ filters }) => filters.date;
export const selectBrandsOnly = ({ filters }) => filters.brandsOnly;
