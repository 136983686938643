import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { useHistory, useLocation } from 'react-router-dom';

const useAuth = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector((state) => state.firebase.auth);
  const isAuthorized = isLoaded(auth) && !isEmpty(auth);

  const { from } = location.state || { from: { pathname: '/' } };
  useEffect(() => {
    if (isAuthorized) {
      history.replace(from);
    }
  }, [isAuthorized, history, from]);
};

export default useAuth;
