import React from 'react';
import PropTypes from 'prop-types';
import { Bar, BarChart, Cell, XAxis } from 'recharts';
import theme from 'styles/theme';
import BarLabel from './BarLabel';

const { gold, green, blue } = theme.colors;
const colors = [gold, green, blue];

const DevicesChart = ({ data }) => (
  <BarChart
    width={135}
    height={130}
    data={data}
    margin={{ left: 0, right: 0, top: 15 }}
    barCategoryGap={10}
    barGap={10}
    barSize={35}
  >
    <Bar dataKey="value" label={<BarLabel />}>
      {data.map((entry, idx) => (
        <Cell key={entry.name} fill={colors[idx % 3]} />
      ))}
    </Bar>
    <XAxis
      dataKey="name"
      tickLine={false}
      stroke={blue}
      strokeWidth={0.1}
      interval={0}
      tick={{ fontSize: '10px', fontWeight: 300, fill: blue }}
    />
  </BarChart>
);

DevicesChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

DevicesChart.defaultProps = {
  data: [],
};

export default DevicesChart;
