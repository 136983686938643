import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormField = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 14px;
`;

export const HelpText = styled.p`
  padding: 0;
  margin: 0;
  margin-bottom: 68px;
  font-size: 15px;
  line-height: 1.15;
`;

export const HelpTextLink = styled(Link)`
  font: inherit;
  color: currentColor;
  text-decoration: none;
  font-weight: 700;
`;
