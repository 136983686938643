import React from 'react';
import catchErrorDecorator from 'helpers/catchErrorDecorator';
import useAuth from 'hooks/useAuth';
import { useFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import LoginView from '../../components/AuthPageView/LoginView';

const Login = () => {
  useAuth();

  const firebase = useFirebase();
  const login = catchErrorDecorator(firebase.login.bind(firebase));

  const loginWithGoogle = () =>
    login({
      provider: 'google',
      type: 'popup',
    });

  const loginWithFb = () =>
    login({
      provider: 'facebook',
      type: 'popup',
    });

  const loginWithEmailAndPassword = ({ email, password }) =>
    login({ email, password });

  const resetPassword = async (email) => {
    try {
      await firebase.resetPassword(email);
      toast(
        'An email with password reset instructions has been sent to your email address'
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <LoginView
      onSubmit={loginWithEmailAndPassword}
      loginWithGoogle={loginWithGoogle}
      loginWithFb={loginWithFb}
      resetPassword={resetPassword}
    />
  );
};

export default Login;
