const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const theme = {
  colors: {
    green: '#3DBEB9',
    gold: '#BEB93D',
    blue: '#353B61',
    red: '#BE3D42',
    white: '#FFFFFF',
    grey: '#F5F5F5',
  },
  mediaQueries: {
    sm: `@media (min-width: ${breakpoints.sm})`,
    md: `@media (min-width: ${breakpoints.md})`,
    lg: `@media (min-width: ${breakpoints.lg})`,
    xl: `@media (min-width: ${breakpoints.xl})`,
  },
  fonts: {
    main: 'Roboto, Ubuntu, Helvetica Neue,sans-serif',
  },
};

export default theme;
