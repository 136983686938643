import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styles';

const Button = ({ children, onClick, inverted, color, size, ...rest }) => (
  <StyledButton
    {...rest}
    color={color}
    size={size}
    inverted={inverted}
    onClick={onClick}
  >
    {children}
  </StyledButton>
);

Button.defaultProps = {
  inverted: false,
  color: 'gold',
  size: 'medium',
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  inverted: PropTypes.bool,
  color: PropTypes.oneOf(['gold', 'red', 'grey', 'blue']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Button;
