import styled from 'styled-components';
import { ReactComponent as Arrow } from 'assets/img/arrow.svg';

export const ReferrersList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const StyledReferrersItem = styled.li`
  display: flex;
  margin-bottom: 9px;
  font-size: 10px;
  font-weight: 500;
  color: ${({ theme, color }) => theme.colors[color]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Referrer = styled.div`
  flex-grow: 1;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`;

export const ArrowWrapper = styled.span`
  position: relative;
  overflow: hidden;
  display: block;
  height: 16px;
  width: 100%;
  margin-top: -5px;
`;

export const StyledArrow = styled(Arrow)`
  position: absolute;
  right: 0;
  flex-grow: 0;
  flex-shrink: 0;
  fill: currentColor;
`;

export const Percentage = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-top: 2px;
  margin-left: 10px;
  font-size: 8px;
  border: 1px solid;
  border-radius: 50%;
  border-color: currentColor;
`;
