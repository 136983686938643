import { timePeriods } from './constants';

export default (period = timePeriods.TODAY) => {
  const now = new Date();
  const dates = {
    get [timePeriods.TODAY]() {
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    },
    get [timePeriods.WEEK]() {
      const day = now.getDay();
      const diff = now.getDate() - day + (day === 0 ? -6 : 1); // Start week from monday
      return new Date(now.getFullYear(), now.getMonth(), diff);
    },
  };
  return dates[period];
};
