import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchOverview } from 'containers/Overview/overviewSlice';
import { loadingState, STATIONS_PER_PAGE } from 'helpers/constants';
import * as apiService from 'services/apiService';
import stationsAdapter from './stationsAdapter';

export const fetchStations = createAsyncThunk(
  'stations/fetch',
  async ({ uid, offset, limit, filter }) => {
    const response = await apiService.getStations(uid, offset, limit, filter);
    return response;
  },
  {
    condition: (payload, { getState }) => {
      const { status } = getState().stations;
      return status !== loadingState.LOADING;
    },
  }
);

const initialState = stationsAdapter.getInitialState({
  offset: 0,
  limit: STATIONS_PER_PAGE,
  total: 0,
  status: loadingState.IDLE,
  error: null,
});

const stationsSlice = createSlice({
  name: 'stations',
  initialState,
  reducers: {
    removeStations() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchStations.pending]: (state) => {
      state.status = loadingState.LOADING;
    },
    [fetchStations.fulfilled]: (state, { payload }) => {
      state.status = loadingState.SUCCEEDED;
      const { stations, offset, total } = payload;
      stationsAdapter.addMany(state, stations || []);
      state.offset = offset;
      state.total = total;
    },
    [fetchStations.rejected]: (state, { error }) => {
      state.status = loadingState.FAILED;
      state.error = error;
    },
    [fetchOverview.fulfilled]: (state, { payload }) => {
      const { topStations } = payload || [];
      stationsAdapter.addMany(state, topStations);
    },
  },
});

export const { removeStations } = stationsSlice.actions;

export default stationsSlice.reducer;

const stationsSelectors = stationsAdapter.getSelectors(
  ({ stations }) => stations
);

export const selectStationsData = ({
  stations: { status, offset, limit, total },
}) => ({ status, offset, limit, total });

export const {
  selectIds: selectStationsIds,
  selectById: selectStationById,
  selectAll: selectStations,
} = stationsSelectors;
