import styled from 'styled-components';

export const StyledHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  min-height: 30px;
  padding: 8px 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.green};
`;

export const HeaderButton = styled.button`
  padding: 0;
  font: inherit;
  font-size: 12px;
  font-weight: 300;
  color: inherit;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
