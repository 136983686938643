import styled from 'styled-components';

export const StyledButton = styled.button`
  font: inherit;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.red};
  text-decoration: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    font-weight: 700;
  }

  &:active {
    color: ${({ theme }) => theme.colors.blue};
  }
`;
