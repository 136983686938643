import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AppRouter from 'scenes/AppRouter/AppRouter';
import store from 'store';
import GlobalFonts from 'assets/fonts';
import GlobalStyles from 'styles/globalStyles';
import theme from 'styles/theme';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase, { config as fbConfig } from 'services/firebase';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

const App = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={firebase}
      dispatch={store.dispatch}
      config={fbConfig}
    >
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        <GlobalStyles />
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
        <ToastContainer />
      </ThemeProvider>
    </ReactReduxFirebaseProvider>
  </Provider>
);

export default App;
