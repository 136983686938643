import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const getSize = (size) => {
  if (size === 'small') {
    return { width: 16, offset: -8, border: 2 };
  }
  if (size === 'large') {
    return { width: 64, offset: -32, border: 6 };
  }
  return { width: 48, offset: -24, border: 4 };
};

const getAnimationSpeed = (size) => {
  if (size === 'small') {
    return 1.5;
  }
  if (size === 'large') {
    return 3;
  }
  return 2;
};

export const StyledLoader = styled.div`
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    ${({ size, color }) => {
      const { width, offset, border } = getSize(size);
      return `
        width: ${width}px;
        height: ${width}px;
        margin-top: ${offset}px;
        margin-left: ${offset}px;
        border-color: ${color};
        border-width: ${border}px;
      `;
    }}
    border-radius: 50%;
    border-style: solid;
    border-left-color: transparent;
    border-bottom-color: transparent;
    animation: ${rotate} linear infinite;
  }

  &::before {
    animation-duration: ${({ size }) => `${getAnimationSpeed(size)}s`};
  }

  &::after {
    animation-duration: ${({ size }) => `${getAnimationSpeed(size) * 0.6}s`};
  }
`;
