import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { HiddenInput, Label, Mark } from './styles';

const Toggle = forwardRef(({ children, checked, ...rest }, ref) => (
  <Label checked={checked}>
    {children}
    <Mark checked={checked} />
    <HiddenInput {...rest} ref={ref} type="checkbox" />
  </Label>
));

Toggle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
};
export default Toggle;
