import { createGlobalStyle } from 'styled-components';

import Roboto300Woff2 from './roboto-v20-latin/roboto-v20-latin-300.woff2';
import Roboto300Woff from './roboto-v20-latin/roboto-v20-latin-300.woff';

import Roboto400Woff2 from './roboto-v20-latin/roboto-v20-latin-regular.woff2';
import Roboto400Woff from './roboto-v20-latin/roboto-v20-latin-regular.woff';

import Roboto500Woff2 from './roboto-v20-latin/roboto-v20-latin-500.woff2';
import Roboto500Woff from './roboto-v20-latin/roboto-v20-latin-500.woff';

import Roboto700Woff2 from './roboto-v20-latin/roboto-v20-latin-700.woff2';
import Roboto700Woff from './roboto-v20-latin/roboto-v20-latin-700.woff';

const GlobalFonts = createGlobalStyle`
 /* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url(${Roboto300Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url(${Roboto300Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url(${Roboto400Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url(${Roboto400Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url(${Roboto500Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url(${Roboto500Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url(${Roboto700Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url(${Roboto700Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
`;

export default GlobalFonts;
