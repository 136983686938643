import React from 'react';
import AuthIsLoaded from 'components/AuthIsLoaded/AuthIsLoaded';
import PrivateRoute from 'containers/PrivateRoute/PrivateRoute';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dashboard from 'scenes/Dashboard/Dashboard';
import Login from 'scenes/Login/Login';
import NotFound from 'scenes/NotFound/NotFound';
import Signup from 'scenes/Signup/Signup';

const AppRouter = () => (
  <BrowserRouter>
    <AuthIsLoaded>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/terms">
          <div>Terms</div>
        </Route>
        <PrivateRoute path="/:uid">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="*">
          <NotFound />
        </PrivateRoute>
      </Switch>
    </AuthIsLoaded>
  </BrowserRouter>
);

export default AppRouter;
