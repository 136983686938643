import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AuthPageView from './AuthPageView';
import SignupForm from '../SignupForm/SignupForm';
import { HelpText, HelpTextLink } from './styles';

const BottomText = () => (
  <HelpText>
    {`Already have an account? `}
    <HelpTextLink
      to={({ state }) => {
        return { pathname: '/login', state };
      }}
    >
      Log in
    </HelpTextLink>
  </HelpText>
);

const SignupView = ({ onSubmit, loginWithGoogle, loginWithFb }) => {
  const consentRef = useRef();
  const loginDecorator = (fn) => (e) => {
    e.preventDefault();
    if (consentRef.current.checkValidity()) {
      return fn();
    }
    return consentRef.current?.reportValidity();
  };

  return (
    <AuthPageView
      loginWithGoogle={loginDecorator(loginWithGoogle)}
      loginWithFb={loginDecorator(loginWithFb)}
      title="Sign up with"
      helpText={<BottomText />}
    >
      <SignupForm ref={consentRef} onSubmit={onSubmit} />
    </AuthPageView>
  );
};

SignupView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loginWithGoogle: PropTypes.func.isRequired,
  loginWithFb: PropTypes.func.isRequired,
};

export default SignupView;
