import React from 'react';
import { selectDateFilter, setDate } from 'containers/Filters/filtersSlice';
import { removeStations } from 'containers/Stations/stationsSlice';
import { timePeriods } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';

import { DateFiltersItem, DateFiltersList, FiltersButton } from './styles';

const DateFilters = () => {
  const date = useSelector(selectDateFilter);
  const dispatch = useDispatch();

  const isToday = date === timePeriods.TODAY;

  const handleClick = (value) => {
    if (value !== date) {
      dispatch(removeStations());
      dispatch(setDate(value));
    }
  };

  return (
    <DateFiltersList>
      <DateFiltersItem>
        <FiltersButton
          onClick={() => handleClick(timePeriods.TODAY)}
          size="small"
          inverted={!isToday}
          disabled={isToday}
        >
          Today
        </FiltersButton>
      </DateFiltersItem>
      <DateFiltersItem>
        <FiltersButton
          onClick={() => handleClick(timePeriods.WEEK)}
          size="small"
          inverted={isToday}
          disabled={!isToday}
        >
          This week
        </FiltersButton>
      </DateFiltersItem>
    </DateFiltersList>
  );
};

export default DateFilters;
