export const loadingState = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export const probTypes = {
  UNIQUE: 'Unique',
  REPEATED: 'Repeated',
};

export const timePeriods = {
  TODAY: 'TODAY',
  WEEK: 'WEEK',
};

export const errorTypes = {
  CLIENT_ERROR: 'CLIENT_ERROR',
  ABORT_ERROR: 'AbortError',
};

export const STATIONS_PER_PAGE = 20;
