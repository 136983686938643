const { createGlobalStyle } = require('styled-components');

const GlobalStyles = createGlobalStyle`
  :root {
    font-size: 16px;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: 14px;
  }

  h1 {
    font-size: 25px;
  }
`;

export default GlobalStyles;
