import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOverview } from 'containers/Overview/overviewSlice';
import {
  fetchStations,
  removeStations,
} from 'containers/Stations/stationsSlice';
import { STATIONS_PER_PAGE } from 'helpers/constants';

const refreshData = createAsyncThunk(
  'refresh',
  async ({ uid, filter }, { dispatch }) => {
    const { type, date, brandsOnly } = filter;
    dispatch(fetchOverview({ uid, type, date, brandsOnly }));
    dispatch(removeStations());
    dispatch(
      fetchStations({ uid, offset: 0, limit: STATIONS_PER_PAGE, filter })
    );
  }
);

export default refreshData;
