import React from 'react';
import PropTypes from 'prop-types';
import { StyledVisuallyHidden } from './styles';

const VisuallyHidden = ({ children, ...rest }) => (
  <StyledVisuallyHidden {...rest}>{children}</StyledVisuallyHidden>
);

VisuallyHidden.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default VisuallyHidden;
