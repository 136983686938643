import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  height: 25px;
  font-size: 12px;
  font-weight: ${({ checked }) => (checked ? 700 : 400)};
`;

export const Mark = styled.span`
  position: relative;
  display: block;
  width: 46px;
  height: 25px;
  margin-left: 7px;
  border-radius: 15px;
  background-color: ${({ theme, checked }) =>
    checked ? theme.colors.red : theme.colors.grey};
  cursor: pointer;
  transition: background-color 0.3s 0s;

  &::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 3.5px;
    display: block;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #ffffff;
    transform: ${({ checked }) => (checked ? 'translateX(16.5px)' : 'none')};
    transition: transform 0.3s ease-in;
  }
`;

export const HiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 8px;
  width: 1px;
`;
