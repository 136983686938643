import React from 'react';
import Container from 'components/shared/Container/Container';
import { useHistory } from 'react-router-dom';
import Button from 'components/shared/Button/Button';
import { useFirebase } from 'react-redux-firebase';
import { StyledButton, StyledPageWrapper } from './styles';

const NotFound = () => {
  const history = useHistory();
  const firebase = useFirebase();

  return (
    <Container>
      <StyledPageWrapper>
        <h1>Page not found</h1>
        <p>You might not have permissions to see this page.</p>
        <p>
          <StyledButton onClick={firebase.logout}>
            Login with a different user
          </StyledButton>
        </p>
        <p>
          <Button color="blue" inverted onClick={history.goBack}>
            Go back →
          </Button>
        </p>
      </StyledPageWrapper>
    </Container>
  );
};

export default NotFound;
