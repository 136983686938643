import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { HeaderButton, StyledHeader } from './styles';

const Header = () => {
  const firebase = useFirebase();

  return (
    <div>
      <StyledHeader>
        <HeaderButton type="button" onClick={firebase.logout}>
          Log out
        </HeaderButton>
      </StyledHeader>
    </div>
  );
};

export default Header;
