import styled from 'styled-components';

export const StyledSocialButton = styled.button`
  box-sizing: border-box;
  width: 87px;
  height: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: inherit;
  font-size: 25px;
  line-height: 1.17em;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
`;
