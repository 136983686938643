import React from 'react';
import PropTypes from 'prop-types';
import theme from 'styles/theme';

const BarLabel = ({ x, y, width, value }) => (
  <text
    x={x + width / 2}
    y={y}
    textAnchor="middle"
    dy={-7}
    fill={theme.colors.blue}
    fontSize={12}
    fontWeight={700}
  >
    {`${value}%`}
  </text>
);

BarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  value: PropTypes.number,
};

BarLabel.defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  value: 0,
};

export default BarLabel;
