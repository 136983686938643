import { combineReducers } from '@reduxjs/toolkit';
import filtersSlice from 'containers/Filters/filtersSlice';
import overviewSlice from 'containers/Overview/overviewSlice';
import stationsSlice from 'containers/Stations/stationsSlice';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  overview: overviewSlice,
  stations: stationsSlice,
  filters: filtersSlice,
  firebase: firebaseReducer,
});

export default rootReducer;
