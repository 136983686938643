import styled, { css } from 'styled-components';

const getBgColor = (theme, color = 'gold', inverted) => {
  if (inverted) {
    return theme.colors.white;
  }
  return theme.colors[color];
};

const getBorderColor = (theme, color = 'gold', inverted) => {
  if (inverted) {
    return `${theme.colors[color]}`;
  }
  return 'transparent';
};

const getPadding = (size) => {
  const paddings = {
    small: '4px 15px',
    medium: '11px 35px',
    large: '16px 64px',
  };

  return paddings[size];
};

const getFontSize = (size) => {
  const values = {
    small: '12px',
    medium: '12px',
    large: '20px',
  };
  return values[size];
};

const getFontWeight = (size) => {
  const values = {
    small: 400,
    medium: 700,
    large: 700,
  };
  return values[size];
};

export const StyledButton = styled.button`
  ${({ theme, inverted, color, size }) => css`
    box-sizing: border-box;
    padding: ${getPadding(size)};
    font: inherit;
    font-size: ${getFontSize(size)};
    line-height: 1.17em;
    font-weight: ${getFontWeight(size)};
    color: ${inverted ? theme.colors.blue : theme.colors.white};
    background-color: ${getBgColor(theme, color, inverted)};
    border: 1px solid;
    border-color: ${getBorderColor(theme, color, inverted)};
    border-radius: 3px;
    cursor: pointer;
    outline: none;
  `}
`;
