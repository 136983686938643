import Loader from 'components/shared/Loader/Loader';
import React from 'react';
import { ScreenWrapper } from './styles';

const SplashScreen = () => (
  <ScreenWrapper>
    <Loader />
  </ScreenWrapper>
);

export default SplashScreen;
