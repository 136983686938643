import callWebApi from 'helpers/callWebApi';
import getDateFromNow from 'helpers/getDateFromNow';

const getDateRange = (timePeriod) => {
  const date = getDateFromNow(timePeriod).getTime();
  const dateEnd = Date.now();
  return [Math.trunc(date / 1e3), Math.trunc(dateEnd / 1e3)];
};

export const getOverview = async (params = {}) => {
  const { uid, date: timePeriod, type, brandsOnly } = params;
  const [date, dateEnd] = getDateRange(timePeriod);

  const response = await callWebApi({
    url: `/api/v1/dashboard/${uid}`,
    method: 'GET',
    params: {
      type,
      date,
      date_end: dateEnd,
      brandsOnly,
    },
  });
  const { data } = response;
  return data;
};

export const getStations = async (uid, offset, limit, filter = {}) => {
  const { date: timePeriod } = filter;
  const [date, dateEnd] = getDateRange(timePeriod);

  const response = await callWebApi({
    url: `/api/v1/stations/${uid}`,
    method: 'GET',
    params: {
      ...filter,
      date,
      date_end: dateEnd,
      offset,
      limit,
    },
  });
  const { data } = response;
  return data;
};
