import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectStationsIds } from 'containers/Stations/stationsSlice';
import ReferrerItem from './ReferrerItem';
import { ReferrersList } from './styles';

const TopReferrers = ({ totalProbes }) => {
  const referrers = useSelector(selectStationsIds).slice(0, 5);
  return (
    <ReferrersList>
      {referrers.map((id, i) => (
        <ReferrerItem key={id} id={id} i={i} totalProbes={totalProbes} />
      ))}
    </ReferrersList>
  );
};

TopReferrers.propTypes = {
  totalProbes: PropTypes.number.isRequired,
};

export default TopReferrers;
