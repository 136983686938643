import React from 'react';
import DevicesChart from 'components/DevicesChart/DevicesChart';
import TopReferrers from 'components/TopReferrers/TopReferrers';
import toPercents from 'helpers/toPercents';
import { useSelector } from 'react-redux';
import { selectOsList, selectTotalProbes } from './overviewSlice';
import { StyledOverview, Column, SectionTitle } from './styles';

const Overview = () => {
  const totalProbes = useSelector(selectTotalProbes);
  const osList = useSelector(selectOsList);

  const formattedOsList = osList.map(({ osName, uniqDevices }) => ({
    name: osName,
    value: toPercents(uniqDevices, totalProbes),
  }));
  const osPercentage = formattedOsList.reduce(
    (sum, { value }) => sum + value,
    0
  );
  const addUnknown = (list, percents) =>
    list.push({ name: 'Unknown', value: percents });
  if (osPercentage && osPercentage < 100) {
    addUnknown(formattedOsList, 100 - osPercentage);
  }

  return (
    <StyledOverview>
      <Column>
        <SectionTitle>Top 5 popular referrers</SectionTitle>
        {Boolean(totalProbes) && <TopReferrers totalProbes={totalProbes} />}
      </Column>
      <Column>
        <SectionTitle>Devices</SectionTitle>
        <DevicesChart data={formattedOsList} />
      </Column>
    </StyledOverview>
  );
};

export default Overview;
