import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import SplashScreen from 'components/SplashScreen/SplashScreen';

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state) => state.firebase.auth);

  return !isLoaded(auth) ? <SplashScreen /> : children;
};

AuthIsLoaded.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthIsLoaded;
