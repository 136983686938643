import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/shared/Container/Container';
import { PageWrapper } from './styles';

const CrashScreen = ({ children }) => (
  <Container>
    <PageWrapper>
      <h2>Something went wrong</h2>
      {children && <p>{children}</p>}
    </PageWrapper>
  </Container>
);

CrashScreen.defaultProps = {
  children: undefined,
};

CrashScreen.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default CrashScreen;
