import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table/Table';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loadingState } from 'helpers/constants';
import TablePlaceholder from 'components/shared/TablePlaceholder/TablePlaceholder';
import Loader from 'components/shared/Loader/Loader';
import formatDate from 'helpers/formatDate';
import toPercents from 'helpers/toPercents';
import {
  selectOverviewLoadingStatus,
  selectTotalProbes,
} from 'containers/Overview/overviewSlice';
import { selectFilters } from 'containers/Filters/filtersSlice';
import {
  fetchStations,
  selectStations,
  selectStationsData,
} from './stationsSlice';

const Stations = ({ uid }) => {
  const totalProbes = useSelector(selectTotalProbes);
  const overviewStatus = useSelector(selectOverviewLoadingStatus);

  const { status, offset, limit, total } = useSelector(
    selectStationsData,
    shallowEqual
  );
  const { type, date, brandsOnly } = useSelector(selectFilters);
  const stationsData = useSelector(selectStations);

  const dispatch = useDispatch();
  useEffect(() => {
    let promise;
    if (status === loadingState.IDLE) {
      promise = dispatch(
        fetchStations({
          uid,
          offset,
          limit,
          filter: { type, date, brandsOnly },
        })
      );
    }
    return () => {
      if (promise) {
        promise.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = async () => {
    return dispatch(
      fetchStations({
        uid,
        offset: offset + limit,
        limit,
        filter: { type, date, brandsOnly },
      })
    );
  };

  const stations = stationsData.map((station) => {
    const lastSeen = new Date(station.lastSeen * 1000); // received date is in seconds, convert to ms

    const percents = toPercents(station.stationProbes, totalProbes);
    const percentage =
      percents <= 1 ? `1% (${station.stationProbes})` : `${percents}%`;

    return {
      ...station,
      lastSeen: formatDate(lastSeen, date),
      stationProbes: percentage,
    };
  });

  const columns = [
    { label: 'Stations', dataKey: 'ssid', size: 0.53 },
    { label: 'Visitors', dataKey: 'stationProbes', size: 0.2 },
    { label: 'Last visited', dataKey: 'lastSeen', size: 0.27 },
  ];
  const isStationsLoaded =
    status === loadingState.IDLE ||
    (status === loadingState.LOADING && stations.length <= 5) ||
    overviewStatus !== loadingState.SUCCEEDED;

  return isStationsLoaded ? (
    <TablePlaceholder>
      <Loader />
    </TablePlaceholder>
  ) : (
    <Table
      columns={columns}
      items={stations}
      hasNextPage={offset + limit < total}
      loadMore={loadMore}
      isNextPageLoading={status === loadingState.LOADING}
    />
  );
};

Stations.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default Stations;
